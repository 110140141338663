module.exports = new Promise(resolve => {
            const script = document.createElement('script');
            script.src = window.honeycomb_url || "https://honeycomb.fieldservices.asurion.com//remoteEntry.js";
            script.onerror = (args) => {
              const proxy = {
                get: request => Promise.resolve().then(() =>()=> ({})),
                init: arg => {}
              };
              resolve(proxy);
            }
            script.onload = (args) => {
              const proxy = {
                get: request => window.honeycomb.get(request),
                init: arg => {
                  try {
                    return window.honeycomb.init(arg);
                  } catch (e) {
                    console.log('honeycomb has already been loaded');
                  }
                }
              };
              resolve(proxy);
            };
            document.head.appendChild(script);
          });