module.exports = new Promise(resolve => {
            const script = document.createElement('script');
            script.src = window.sbx_operations_servicescheduling_expertcalendar_ui_url || "https://expertcalendar.sbx.prod.servicebench.com/remoteEntry.js";
            script.onerror = (args) => {
              const proxy = {
                get: request => Promise.resolve().then(() =>()=> ({})),
                init: arg => {}
              };
              resolve(proxy);
            }
            script.onload = (args) => {
              const proxy = {
                get: request => window.sbx_operations_servicescheduling_expertcalendar_ui.get(request),
                init: arg => {
                  try {
                    return window.sbx_operations_servicescheduling_expertcalendar_ui.init(arg);
                  } catch (e) {
                    console.log('sbx_operations_servicescheduling_expertcalendar_ui has already been loaded');
                  }
                }
              };
              resolve(proxy);
            };
            document.head.appendChild(script);
          });